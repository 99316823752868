.ui.sortable.table thead th.sorted,
.ui.sortable.table thead th.sorted:hover,
.ui.sortable.table thead th:hover {
  background: #f2f2f2;
}

.react-datepicker {
  display: flex;
}
.react-datepicker__input-container {
}
.react-datepicker__input-container input {
  outline: none;
  width: 100%;
}
pre {
  font-family: inherit;
}
.header-violet {
  background: #392272;
}
.header-yellow {
  background: #f3e522 !important;
}
/* .ui.menu {
  background: #392272;
}
.ui.menu .item,
.ui.menu .item::before,
.ui.menu .item::after {
  background: none;
  border: none !important;
  padding: 0;
} */
.triangle-bottomleft {
  width: 0;
  height: 0;
  border-bottom: 34px solid #f3e522;
  border-right: 34px solid transparent;
}

.triangle-bottomleft-brown {
  width: 0;
  height: 0;
  border-bottom: 34px solid #eed4b3;
  border-right: 34px solid transparent;
}
.triangle-bottomleft-white {
  width: 0;
  height: 0;
  border-bottom: 34px solid white;
  border-right: 34px solid transparent;
}
.triangle-bottomleft-grey {
  width: 0;
  height: 0;
  border-bottom: 34px solid #ecf0f1;
  border-right: 34px solid transparent;
}

.pagination {
}
